.topnav {
	/* background-color: rgb(52, 23, 131); */
	background-image: linear-gradient(
		to right,
		rgb(115, 54, 241),
		rgb(52, 23, 131)
	);

	/* background-color: #333; */
	overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a,
.topnav button {
	float: left;
	display: block;
	/* background-color: rgb(99, 48, 217); */
	/* background-image: linear-gradient(
		to right,
		rgb(52, 23, 131),
		rgb(99, 48, 217)
	); */
	overflow: hidden;
	/* background-color: #333; */
	color: #f2f2f2;
	text-align: center;
	padding: 14px 17px;
	text-decoration: none;
	font-size: 20px;
	border: none;
	border-radius: 0px;
	font-weight: 400;
}
.topnav button {
	background-color: rgb(99, 48, 217);
}
/* Change the color of links on hover */
.topnav a:hover {
	background-color: #ddd;
	color: black;
}

/* Add an active class to highlight the current page */
.topnav a.active,
.topnav button.active {
	color: white;
}
.topnav button.active {
	background-color: rgb(115, 54, 241);
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
	display: none;
	max-height: 48px;
	max-width: unset;
}
.topnav .nav-right {
	float: right;
}

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 600px) {
	.topnav a:not(:first-child) {
		display: none;
	}
	.topnav button {
		display: none;
	}
	.topnav button.icon {
		float: right;
		display: block;
	}
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 600px) {
	.topnav.responsive {
		position: relative;
	}
	.topnav.responsive .icon {
		position: absolute;
		right: 0;
		top: 0;
	}
	.topnav.responsive a,
	.topnav.responsive button {
		float: none;
		display: block;
		text-align: left;
	}
	.topnav.responsive .nav-right {
		float: none;
	}
}
