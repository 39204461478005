.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

h1 {
	font-size: larger;
	line-height: 1.1em;
}

h2 {
	font-size: larger;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.body-content {
	padding-top: 1em;
	padding-left: 2em;
	padding-right: 2em;
	font-size: large;
}

footer {
	margin-top: 3em;
}

.options {
	border-style: solid;
	border-width: 1px;
	padding: 1em;
}

.options-title {
	font-weight: bold;
	font-size: large;
}

.star {
	display: inline-block;
}

.wishlist-edit-invite-new {
	margin-bottom: 1em;
}

a.wishlist-list-item,
a.wishlist-show-item,
a.wishlist-show-item-small {
	text-decoration: none;
}

a.wishlist-list-item:hover,
a.wishlist-show-item:hover,
a.wishlist-show-item-small:hover {
	text-decoration: underline;
}

.wishlist-show-list {
	display: block;
}

.wishlist-show-list-small {
	display: none;
}

.wishlist-show-list-small .add-new {
	display: block;
	margin-top: 1em;
	margin-bottom: 1em;
}

.wishlist-show-item-small-price {
	margin-left: 2.5em;
	width: 2em;
}

.wishlist-show-item-small-eye {
	/* padding-left: 2em; */
	height: 32px;
}

.wishlist-show-item-archive {
	height: 32px;
}

.wishlist-show-item-small-bought-date {
	width: 13em;
}

.wishlist-show-item-small-buy {
	margin-left: 4.5em;
}

.wishlist-show-item-small-return {
	margin-left: 0em;
}

.wishlist-item-show-dialog {
	margin-bottom: 1em;
}

.wishlist-item-show-dialog label {
	font-weight: bold;
	margin-top: 0.5em;
}

.wishlist-item-edit-dialog,
.wishlist-item-buy-dialog,
.wishlist-edit-dialog {
	margin-bottom: 1em;
	max-width: 500px;
}

.wishlist-item-edit-dialog label,
.wishlist-edit-dialog label {
	font-weight: bold;
	margin-top: 0.5em;
}

.wishlist-show-bought-items-link,
.wishlist-show-archived-items-link {
	font-size: smaller;
	margin-bottom: 0.5em;
}

@media (max-width: 540px) {
	.body-content {
		padding-top: 0;
		padding-left: 0.5em;
		padding-right: 0.5em;
		font-size: large;
	}

	.wishlist-show-list {
		display: none;
	}

	.wishlist-show-list-small {
		display: block;
	}

	.wishlist-show-item-small {
		padding: 0;
		margin: 0;
	}
}